<template>
  <component :is="tag" class="footer-menu">
    <BaseButton
      v-for="route in routes"
      :key="`${route.title}-footer`"
      class="footer-nav-btn"
      :color="route.color || 'white'"
      flat
      small
      :to="route.to ? route.to.path : ''"
      :href="route.href"
      :target="route.target"
      >{{ route.title }}</BaseButton
    >
    <BaseButton
      v-for="(channel, count) of getSocialChannels"
      :key="channel.name"
      :class="[
        'py-0 pl-2 social-channel',
        {
          'pr-2 mr-2': count > 1,
        },
      ]"
      :href="channel.url"
      target="_blank"
      color="transparent"
      :aria-label="channel.name"
    >
      <img
        :src="require(`@assets/images/icon-${channel.name}-white.svg`)"
        :alt="channel.name"
      />
    </BaseButton>

    <BaseButton
      color="bubs-red"
      text-color="white"
      small
      class="mr-0 footer-nav-btn"
      @click="isOrderDialogOpen = true"
      >Order Online</BaseButton
    >
  </component>
</template>

<script>
import formatPhone from '@utils/format-phone'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'BubsGiantFooterMenu',
  components: {},
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      routes: [
        {
          title: 'Catering',
          to: { path: '/catering' },
          exact: true,
          position: 'center',
        },
        {
          title: 'Merchandise',
          href: 'https://bubsnola.store/',
          exact: true,
          position: 'center',
        },
        {
          title: 'About & Press',
          to: { path: '/about-and-press' },
          exact: true,
          position: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('site', ['getSocialChannels']),
    ...mapFields('nav', ['isOrderDialogOpen']),
  },
  methods: {
    formatPhone,
    openOrderDialog() {}, // openOrderDialog
  },
}
</script>

<style lang="scss">
@import '@styleMixins';

.footer-menu .btn-base {
  @include btn-size(0.8rem, 0.75em);

  margin-right: 0.3em;
  margin-left: 0.3em;

  .v-btn__content {
    @extend .rubik--font;
  }
}
.social-channel {
  height: 32px;
  padding: 0 !important;
  margin: 0 !important;
}
</style>
